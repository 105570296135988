import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { Observable, forkJoin, BehaviorSubject, of, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ReportApi } from '../api/report.api';


@Injectable()
export class ReportService {

  constructor(private api: ReportApi) {

  }

  getEstatements(startDate, endDate,locationCode,sinceLaunch,locationType,conferenceTitleId) {
   return this.api.getEstatements(startDate, endDate,locationCode,sinceLaunch,locationType,conferenceTitleId);

   }

  getEstatementsByLanguage(startDate, endDate,locationCode,sinceLaunch,locationType,conferenceTitleId) {
    return this.api.getEstatementsByLanguage(startDate, endDate,locationCode,sinceLaunch,locationType,conferenceTitleId);
  }

  getEStatementsAccessed(startDate, endDate,locationType, code, byLang){
    return this.api.getEStatementsAccessed(startDate, endDate,locationType, code, byLang);
  }

  getEstatementsAccessedSinceLaunch(locationType, code, byLang){
    return this.api.getEStatementsAccessedSinceLaunch(locationType, code,byLang)
  }

  getAddedSummariesTemplates(startDate, endDate) {
    return this.api.getAddedSummariesTemplates(startDate, endDate);
  }

  getTotalNumberOfSummaries(startDate, endDate, locationCode,locationType,conferenceTitleId) {
    return this.api.getTotalNumberOfSummaries(startDate, endDate, locationCode,locationType,conferenceTitleId);
  }

  getNumberOfSummariesByFilter(startDate,endDate,locationCode, reportSubType,locationType,conferenceTitleId) {
    return this.api.getNumberOfSummariesByFilter(startDate,endDate,locationCode, reportSubType,locationType,conferenceTitleId);
  }

  getNumberOfSummariesUsed(startDate,endDate,locationCode,locationType,conferenceTitleId){
    return this.api.getNumberOfSummariesUsed(startDate,endDate,locationCode,locationType,conferenceTitleId);
  }

  getSummaryOrgans(startDate,endDate,locationCode,locationType,conferenceTitleId){
    return this.api.getSummaryOrgans(startDate,endDate,locationCode,locationType,conferenceTitleId);
  }

  getNumberGIAnnouncements(locationCode, startDate, endDate,locationType) {
    return this.api.getNumberGIAnnouncements(locationCode, startDate, endDate,locationType);
  }

  gGetPDFDownloads(locationCode, startDate, endDate) {
    return this.api.gGetPDFDownloads(locationCode, startDate, endDate);
  }

  getNumberOfICJAnnouncements(locationCode, startDate, endDate) {
    return this.api.getNumberOfICJAnnouncements(locationCode, startDate, endDate);
  }
  getNumberOfOtherMeetings( startDate, endDate,locationType,conferenceTitleId, locationCode) {
    return this.api.getNumberOfOtherMeetings( startDate, endDate,locationType,conferenceTitleId, locationCode);
  }


  getMetingsAnnouncedPerMeetingType(locationCode, startDate, endDate,locationType,conferenceTitleId) {
    return this.api.getMetingsAnnouncedPerMeetingType(locationCode, startDate, endDate,locationType,conferenceTitleId);
  }

  GetNumberOfJournalsIssued(locationCode, startDate, endDate,locationType,conferenceTitleId) {
    return this.api.GetNumberOfJournalsIssued(locationCode, startDate, endDate,locationType,conferenceTitleId);
  }

  GetTranslationPercentage(startDate, endDate, paramName){
    return this.api.GetTranslationPercentage(startDate, endDate, paramName);
  }

  GetNumberOfAgendaCards(locationCode, startDate, endDate){
    return this.api.GetNumberOfAgendaCards(locationCode,startDate, endDate);
  }

  getLocations() {
    return this.api.getLocations();
  }

  getParameters(){
    return this.api.getParameters();
  }
  getMeetingCategoriesForDownload(startDate, endDate, locationCode,locationType,conferenceTitleId){
    return this.api.getMeetingCategoriesForDownload( startDate, endDate, locationCode,locationType,conferenceTitleId);
  }

  getNumberOfWebCastsAnnounced(startDate, endDate, locationCode){
    return this.api.getNumberOfWebCastsAnnounced( startDate, endDate, locationCode);

  }
  getNumberOfWebCastsAnnouncedPerCategory(startDate, endDate, locationCode){
    return this.api.getNumberOfWebCastsAnnouncedPerCategory( startDate, endDate, locationCode);

  }
  getWebCastsCategoriesForDownload(startDate, endDate, locationCode){
    return this.api.getWebCastsCategoriesForDownload( startDate, endDate, locationCode);
  }

  getDutyStations() {
    return this.api.getDutyStations();
  }
  getConferenceLocations() {
    return this.api.getConferenceLocations();
  }

  getconferenceTitles() {
    return this.api.getconferenceTitles();
  }

  getconferenceSymbolsAndTitles() {
    return this.api.getconferenceSymbolsAndTitles();
  }

  getPrincipalOrgansList() {
    return this.api.getPrincipalOrgansList();
  }

  getNumberOfConferences(startDate, endDate, locationCode, isServicingDutyStation) {
    return this.api.getNumberOfConferences(startDate, endDate, locationCode, isServicingDutyStation);
  }

  getNumberOfConferencesByOrgan(startDate, endDate, organId) {
    return this.api.getNumberOfConferencesByOrgan(startDate, endDate, organId);
  }

  getNumberOfConferencesSinceLaunch(startDate, endDate, locationCode) {
    return this.api.getNumberOfConferencesSinceLaunch(startDate, endDate, locationCode);
  }
}
